import React from 'react'
import icAvatar from './ic_avatar.webp'
import styled from 'styled-components'

const StyledContainer = styled.div`
background-color: #F7F7F7 !important;
position: fixed !important;
bottom: 0px !important;
width: 100% !important;
z-index: 9999 !important;
padding-bottom: 1.25rem !important;
height: 5rem !important;

@media (max-width: 1120px) {
    background-color: #EDF1EC !important;
    padding-bottom: 0.75rem !important;
    height: auto !important;
}
`

const StyledDiv = styled.div`
max-width: 831px !important;
margin: 0 auto !important;
position: relative !important;

@media (max-width: 640px) {
    max-width: 100% !important;
    margin: 0 32px 0 16px !important;
}

@media (min-width: 640px ) and (max-width: 1120px) {
    max-width: 600px !important;
}
`

const StyledHeadContainer = styled.div`
display: flex !important;
align-items: center !important;
padding-top: 1.5rem !important;

@media (max-width: 1120px) {
    padding-top: 1rem !important;
}
`

const StyledHead = styled.img`
width: 40px !important;
height: 40px !important;
border-radius: 57px !important;
--tw-shadow: 0 4px 13px 0 rgba(0,0,0,0.29);
    --tw-shadow-colored: 0 4px 13px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;

@media (max-width: 1120px) {
    width: 2.5rem !important;
    height: 2.5rem !important;
    border-radius: 40px !important;
    --tw-shadow: 0 3px 11px 0 rgba(0,0,0,0.29);
        --tw-shadow-colored: 0 3px 11px 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
`

const StyledSpan = styled.span`
color: rgb(1 45 45) !important;
font-size: 1.0625rem/* 17px */ !important;
margin-left: 1.25rem !important;

@media (max-width: 1120px) {
    font-size: 0.8125rem/* 13px */ !important;
    margin-left: 14px !important;
    line-height: 1.125rem/* 18px */;
}

@media (max-width: 576px) {
    width: 142px !important;

}
`

const StyledButton = styled.a`
width: 106px !important;
height: 2rem !important;
display: block !important;
margin-left: auto !important;
margin-right: 0 !important;
padding-top: 0.625rem !important;
background-color: #1283FF !important;
color: #fff !important;
font-size: 0.625rem/* 10px */ !important;
font-weight: 700 !important;
text-align: center !important;
letter-spacing: 0.1em !important;
text-decoration: none !important;
border-radius: 16px !important;

@media (max-width: 1120px) {
    width: 91px !important;
    height: 27px !important;
    font-size: 0.5rem/* 8px */  !important;
    padding-top: 0.5rem !important;
    align-self: center !important;
}

&:hover {
    opacity: 0.8 !important;
}
`

const BookConsultationBanner: React.FC<{}> = ({ }) => {
    return (
        <StyledContainer>
            <StyledDiv>
                <StyledHeadContainer>
                    <StyledHead
                        src={icAvatar}
                        alt="avatar"
                    />
                    {/* <span className="text-blue-1 text-17/16 ml-5 lg:text-13/16 lg:ml-4 xs1:w-[142px] lg:leading-18/16">
            Get in touch to arrange a free consultation call!
          </span> */}
                    <StyledSpan>
                        Book a consultation to have a walkthrough of all the policies!
                    </StyledSpan>
                    <StyledButton
                        href="https://calendly.com/taylor-airmason/free-consultation"
                        target="_blank"
                    >
                        BOOK NOW
                    </StyledButton>
                </StyledHeadContainer>
            </StyledDiv>
        </StyledContainer>
    )
}

export default BookConsultationBanner
