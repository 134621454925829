import React, { useEffect, useState } from "react"
import { PolicyTab } from "../../../utils/policies-finder"
import { MultiSelect } from "react-multi-select-component"
import { CSVLink } from "react-csv"
import rawPolicies, { Policy } from "./rawPolicies"
import rawStates from "./rawStates"
import { slackAxiosInstance } from "../../../api/slackAxiosInstance"
import requiredIcon from "../../../images/policies-finder/ic_required.svg"
import recommendedIcon from "../../../images/policies-finder/ic_recommended.svg"
import closeIcon from "../../../images/policies-finder/ic_close.svg"
import downloadIcon from "../../../images/policies-finder/ic_download.svg"
import { useClickAway } from "../../../utils/utilHooks"
import config from "../../../config"
import { freeBasicEmailDomain } from "../../../utils/freeBasicEmailDomain"
import useSendMessagePoliciesFinder from "../../../hooks/useSendMessagePoliciesFinder"

const IndustryOptions = [
  { label: "Select all industries", value: "all", isPickAll: true },
  { label: "Technology", value: "technology" },
  { label: "Healthcare", value: "healthcare" },
  { label: "Hospitality", value: "hospitality" },
  { label: "Manufacturing", value: "manufacturing" },
  { label: "Professional services", value: "professional_services" },
  { label: "Non-profit", value: "non_profit" },
  { label: "Government", value: "government" },
  { label: "Trucking", value: "trucking" },
  { label: "Construction", value: "construction" },
  { label: "Other", value: "other" },
]

const policies: Policy[] = rawPolicies.filter(
  policy => policy.region !== "Federal"
)
const federalPolicies: Policy[] = rawPolicies.filter(
  policy => policy.region === "Federal"
)

const PoliciesTableDesktop = ({ policiesToShowOnUI }: any) => {
  return (
    <div className="policies-table pt-5">
      {policiesToShowOnUI.length > 0 ? (
        <>
          {policiesToShowOnUI.map((policy: any, index: any) => {
            return (
              <div
                key={index}
                className="policy-card flex flex-row justify-between rounded-lg border border-gray-6 pt-29px pb-17px pl-33px pr-18px my-2"
              >
                <div className="text-div max-w-591px">
                  <div className="card-title font-bold text-base leading-14px text-green-4">
                    {policy.label}
                  </div>
                  <div className="card-description text-13px leading-21px text-gray-5 pt-10px">
                    {policy.description}
                  </div>
                </div>
                <div className="label-div max-w-97px">
                  <div className="w-97px rounded-full border-gray-1 border">
                    <p className="text-center py-5px text-11px text-green-4">
                      {policy.regions.length > 1
                        ? `${policy.regions[0]}+${policy.regions.length - 1}`
                        : policy.regions[0]}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

const PoliciesTableMobile = ({ policiesToShowOnUI }: any) => {
  return (
    <div className="policies-table block sm:hidden bg-white">
      {policiesToShowOnUI.length > 0 ? (
        <>
          {policiesToShowOnUI.map((policy: any, index: any) => {
            return (
              <div
                key={index}
                className="policy-card flex flex-row justify-between border-gray-6 pt-29px pb-17px mb-2 last:mb-0 last:border-b-0"
                style={{ borderBottom: "0.73px" }}
              >
                <div className="text-div max-w-591px">
                  <div className="card-title font-bold text-base leading-14px text-green-4">
                    {policy.label}
                  </div>
                  <div className="w-97px rounded-13px border-gray-1 border mt-4">
                    <p className="text-center py-5px text-11px text-green-4">
                      {policy.regions.length > 1
                        ? `${policy.regions[0]}+${policy.regions.length - 1}`
                        : policy.regions[0]}
                    </p>
                  </div>
                  <div className="card-description text-13px leading-20px text-gray-5 pt-10px">
                    {policy.description}
                  </div>
                </div>
              </div>
            )
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

interface PolicyTabsProps {
  currentTab: PolicyTab
  setCurrentTab: (tab: PolicyTab) => void
  totalRequiredPolicies: number
  totalRecommendedPolicies: number
  selectedStates: { label: string; value: string }[]
  clickingGo: boolean
  policiesToShowOnUIRequired: Policy[]
  policiesToShowOnUIRecommended: Policy[]
  federalPoliciesMerged: Policy[]
}

const PolicyTabs = ({
  currentTab,
  setCurrentTab,
  clickingGo,
  selectedStates,
  totalRequiredPolicies,
  totalRecommendedPolicies,
  policiesToShowOnUIRequired,
  policiesToShowOnUIRecommended,
  federalPoliciesMerged,
}: PolicyTabsProps) => {
  const [requiredSelected, setRequiredSelected] = useState(false)
  const [recommendedSelected, setRecommendedSelected] = useState(false)

  return (
    <div className="tab-section flex flex-col sm:flex-row bg-white">
      <div
        onClick={() => {
          setCurrentTab(PolicyTab.REQUIRED)
          setRequiredSelected(!requiredSelected)
        }}
        style={{ columnGap: "21px" }}
        className={`first-tab w-full sm:w-1/2 sm:px-22px px-27px py-24px flex sm:flex-row  cursor-pointer bg-white ${currentTab ===
          PolicyTab.RECOMMENDED && "bg-gray-11"}`}
      >
        <div
          className={`w-79px h-62px border border-gray-1 rounded-13px flex ${
            totalRequiredPolicies > 0 ? "border" : "border-dashed"
          }`}
        >
          <div
            className={`font-bold text-4xl m-auto text-green-4 ${totalRequiredPolicies ===
              0 && "text-green-4"}`}
          >
            {!clickingGo ? totalRequiredPolicies : 0}
          </div>
        </div>
        <div className="required-div py-2 flex flex-col justify-around">
          <div
            className="tab-label flex flex-row items-center"
            style={{ columnGap: "13px" }}
          >
            <p className="text-21px text-green-4">Required</p>
            <img
              className=""
              src={requiredIcon}
              alt="required-icon"
              style={{ width: "13px", height: "13px" }}
            />
          </div>
          <div className="tab-description">
            <p className="text-xs leading-14px text-gray-5">
              Legally required for your company.
            </p>
          </div>
        </div>
      </div>

      {requiredSelected && (
        <div className="block sm:hidden overflow-scroll max-h-400px">
          {(policiesToShowOnUIRequired.length > 0 ||
            (selectedStates.filter(state => state.value === "federals")
              .length === 1 &&
              clickingGo === false)) && (
            <div className="px-33px bg-white">
              {selectedStates.filter(state => state.value === "federals")
                .length === 1 &&
                clickingGo === false && (
                  <>
                    <div className="total-policies pb-6 bg-white border-b border-gray-6">
                      <p>
                        <span className="text-base text-green-4">
                          Federal policies
                        </span>
                        <span className="text-base text-green-4">{` (${federalPoliciesMerged.length})`}</span>
                      </p>
                    </div>
                    <PoliciesTableMobile
                      policiesToShowOnUI={federalPoliciesMerged}
                    />
                  </>
                )}
              {/* Mobile here */}
              <div className="total-policies py-6 border-b border-gray-6">
                <p>
                  <span className="text-base text-green-4">
                    State specific policies
                  </span>
                  <span className="text-base text-green-4">{` (${policiesToShowOnUIRequired.length})`}</span>
                </p>
              </div>
              {/* Mobile here */}
              <PoliciesTableMobile
                policiesToShowOnUI={policiesToShowOnUIRequired}
              />
            </div>
          )}
        </div>
      )}

      <div
        onClick={() => {
          setCurrentTab(PolicyTab.RECOMMENDED)
          setRecommendedSelected(!recommendedSelected)
        }}
        style={{ columnGap: "21px" }}
        className={`second-tab w-full sm:w-1/2 sm:px-22px px-27px py-24px flex sm:flex-row  cursor-pointer bg-white ${currentTab ===
          PolicyTab.REQUIRED && "bg-gray-11"}`}
      >
        <div
          className={`w-79px h-62px border border-gray-1 rounded-13px flex ${
            totalRecommendedPolicies > 0 ? "border" : "border-dashed"
          }`}
        >
          <div
            className={`font-bold text-4xl m-auto text-green-4 ${totalRecommendedPolicies ===
              0 && "text-green-4"}`}
          >
            {totalRecommendedPolicies}
          </div>
        </div>
        <div className="required-div py-2 flex flex-col justify-around">
          <div
            className="tab-label flex flex-row items-center"
            style={{ columnGap: "13px" }}
          >
            <p className="text-21px text-green-4">Recommended</p>
            <img
              className=""
              src={recommendedIcon}
              alt="remmended-icon"
              style={{ width: "12px", height: "12px" }}
            />
          </div>
          <div className="tab-description">
            <p className="text-xs leading-14px text-gray-5">
              Nice-to-have but not essential.
            </p>
          </div>
        </div>
      </div>
      {recommendedSelected && (
        <div className="block sm:hidden overflow-scroll max-h-400px">
          {policiesToShowOnUIRecommended.length > 0 && (
            <div className="px-33px bg-white">
              <div className="total-policies pb-6 border-b border-gray-6">
                <p>
                  <span className="text-base text-green-4">
                    All recommended policies
                  </span>
                  <span className="text-base text-green-4">{` (${totalRecommendedPolicies})`}</span>
                </p>
              </div>
              <PoliciesTableMobile
                policiesToShowOnUI={policiesToShowOnUIRecommended}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const PoliciesSection: React.FC<{}> = ({}) => {
  const [currentTab, setCurrentTab] = useState(PolicyTab.REQUIRED)
  const [clickingGo, setClickingGo] = useState(false)
  const [selectedStates, setSelectedStates] = useState(
    rawStates.filter(state => state.value === "federals")
  ) as any
  const [inputEmployeeCount, setInputEmployeeCount] = useState(1000)
  const [selectedIndustries, setSelectedIndustries] = useState<
    { label: string; value: string }[] | never[]
  >(IndustryOptions.filter(industry => industry.value === "other"))
  const [currentPolicies, setCurrentPolicies] = useState([]) as any
  const { submit: sendEmailToCustomer } = useSendMessagePoliciesFinder()
  const [filterDiv1Hovered, setFilterDiv1Hovered] = useState(false)
  const [filterDiv2Hovered, setFilterDiv2Hovered] = useState(false)
  const [filterDiv3Hovered, setFilterDiv3Hovered] = useState(false)

  function handleActiveOutline(selector: string): void {
    let filterDiv = document.querySelector(
      `.policies-section .filter-div`
    ) as any
    let dropdownDivElement = null
    let labelElement = null
    switch (selector) {
      case "state-dropdown":
        dropdownDivElement = document.querySelector(
          ".multi-select-special-1 .dropdown-content"
        )
        labelElement = document.querySelector(".state-label")
        break
      case "industry-dropdown":
        dropdownDivElement = document.querySelector(
          ".multi-select-special-2 .dropdown-content"
        )
        labelElement = document.querySelector(".industry-label")
        break
      default:
        dropdownDivElement = null
    }
    if (filterDiv && !dropdownDivElement && labelElement) {
      filterDiv.classList.add("active-outline")
      labelElement.classList.add("active")
    }
  }

  useEffect(() => {
    const multiSelectSpecial1 = document.getElementsByClassName(
      "multi-select-special-1"
    )
    if (multiSelectSpecial1) {
      multiSelectSpecial1[0].addEventListener("click", () =>
        handleActiveOutline("state-dropdown")
      )
    }
    const multiSelectSpecial2 = document.getElementsByClassName(
      "multi-select-special-2"
    )
    if (multiSelectSpecial2) {
      multiSelectSpecial2[0].addEventListener("click", () =>
        handleActiveOutline("industry-dropdown")
      )
    }
  }, [])

  const mergeObjects = (dataArray: any): Policy[] => {
    const uniqueObjects: any = {}

    dataArray.forEach((obj: any) => {
      const key = `${obj.label}|${obj.description}`

      // Initialize the unique object if it hasn't been added yet
      if (!uniqueObjects[key]) {
        uniqueObjects[key] = {
          ...obj,
          regions: [obj.region],
        }
        delete uniqueObjects[key].region // We will replace 'region' with 'regions'
      } else {
        uniqueObjects[key].regions.push(obj.region)
      }
    })

    return Object.values(uniqueObjects)
  }

  const policiesToShowOnUI = mergeObjects(currentPolicies)

  const policiesToShowOnUIRequired = policiesToShowOnUI.filter(e => e.required)
  const policiesToShowOnUIRecommended = policiesToShowOnUI.filter(
    e => !e.required
  )

  const totalRequiredPolicies =
    policiesToShowOnUIRequired.length +
    (selectedStates.filter((state: any) => state.value === "federals")
      .length === 1
      ? federalPolicies.length
      : 0)
  const totalRequiredStateSpecificPolicies = policiesToShowOnUIRequired.length
  const totalRecommendedPolicies = policiesToShowOnUIRecommended.length
  const [showModal, setShowModal] = useState(false)
  const stateRef: any = useClickAway(() => {
    handleLogicStateDropdown()
  })

  const industryRef: any = useClickAway(() => {
    handleLogicIndustryDropdown()
  })

  function handleLogicStateDropdown(): void {
    let clickEventState = document.querySelector(
      ".multi-select-special-1 .dropdown-heading"
    ) as any
    let dropdownContent1IsShow = document.querySelector(
      ".multi-select-special-1 .dropdown-content"
    )
    let filterDiv = document.querySelector(
      `.policies-section .filter-div`
    ) as any
    let labelElement = document.querySelector(".state-label")
    if (filterDiv && labelElement) {
      filterDiv.classList.remove("active-outline")
      labelElement.classList.remove("active")
    }
    if (dropdownContent1IsShow) {
      clickEventState.click()
    }
  }

  function handleLogicIndustryDropdown(): void {
    let clickEventIndustry = document.querySelector(
      ".multi-select-special-2 .dropdown-heading"
    ) as any
    let dropdownContent2IsShow = document.querySelector(
      ".multi-select-special-2 .dropdown-content"
    )
    let filterDiv = document.querySelector(
      `.policies-section .filter-div`
    ) as any
    let labelElement = document.querySelector(".industry-label")
    if (filterDiv && labelElement) {
      filterDiv.classList.remove("active-outline")
      labelElement.classList.remove("active")
    }
    if (dropdownContent2IsShow) {
      clickEventIndustry.click()
    }
  }

  function canPressGoButton(): any {
    return (
      selectedStates.length > 0 &&
      inputEmployeeCount > 0 &&
      selectedIndustries.length > 0
    )
  }

  function handlePickAllStates(): any {
    let clonedOptions = JSON.parse(JSON.stringify(selectedStates))
    if (clonedOptions.find((e: any) => e.value === "all")) {
      clonedOptions = []
    } else {
      clonedOptions = JSON.parse(JSON.stringify(rawStates))
    }
    return clonedOptions
  }

  function handlePickOneState({
    label,
    value,
  }: {
    label: string
    value: string
  }): any {
    let clonedOptions = JSON.parse(JSON.stringify(selectedStates))
    if (clonedOptions.find((e: any) => e.value === value)) {
      clonedOptions = clonedOptions.filter((e: any) => e.value !== value)
    } else {
      clonedOptions.push({ label, value })
    }
    if (
      clonedOptions.length === rawStates.length - 1 &&
      !clonedOptions.find((e: any) => e.value === "all")
    ) {
      clonedOptions.push({ label: "Select all states", value: "all" })
    } else {
      clonedOptions = clonedOptions.filter((e: any) => e.value !== "all")
    }
    return clonedOptions
  }

  function handlePickAllIndustries(): any {
    let clonedOptions = JSON.parse(JSON.stringify(selectedIndustries))
    if (clonedOptions.find((e: any) => e.value === "all")) {
      clonedOptions = []
    } else {
      clonedOptions = JSON.parse(JSON.stringify(IndustryOptions))
    }
    return clonedOptions
  }

  function handlePickOneIndustry({
    label,
    value,
  }: {
    label: string
    value: string
  }): any {
    let clonedOptions = JSON.parse(JSON.stringify(selectedIndustries))
    if (clonedOptions.find((e: any) => e.value === value)) {
      clonedOptions = clonedOptions.filter((e: any) => e.value !== value)
    } else {
      clonedOptions.push({ label, value })
    }
    if (
      clonedOptions.length === IndustryOptions.length - 1 &&
      !clonedOptions.find((e: any) => e.value === "all")
    ) {
      clonedOptions.push({ label: "Select all states", value: "all" })
    } else {
      clonedOptions = clonedOptions.filter((e: any) => e.value !== "all")
    }
    return clonedOptions
  }

  function handleClickGo(): any {
    const allPoliciesToShow = policies.filter(policy =>
      selectedStates.map((state: any) => state.label).includes(policy.region)
    )
    setCurrentPolicies(allPoliciesToShow)
    setClickingGo(false)
  }

  const DownloadCSV = () => {
    return (
      <div className="download-div flex flex-row items-center">
        <CSVLink
          id="csv-download-link"
          className="font-bold text-sm text-blue-2 cursor-pointer hover:text-blue-2 hover:brightness-75 hidden"
          data={[...federalPolicies, ...currentPolicies]}
          headers={[
            { label: "Policy Title", key: "label" },
            // { label: "Policy Description", key: "description" },
            { label: "Region", key: "region" },
            { label: "Required?", key: "required" },
          ]}
          separator={","}
          filename={`your-tacklehr-policies-${new Date()
            .toISOString()
            .substring(0, 10)}.csv`}
        >
          Download .CSV
        </CSVLink>
        <span
          className="font-bold text-sm text-blue-2 cursor-pointer hover:text-blue-2 hover:brightness-75"
          onClick={() => {
            let isAlreadyInputted = sessionStorage.getItem("modal-inputted")
            if (isAlreadyInputted) {
              let ele = document.getElementById("csv-download-link")
              if (ele) {
                ele.click()
              }
            } else {
              handleOpenModal()
            }
          }}
        >
          Download .CSV
        </span>
        <img
          className="ml-19px"
          src={downloadIcon}
          alt="download-icon"
          style={{ width: "18px", height: "12px" }}
        />
      </div>
    )
  }

  function handleOpenModal(): any {
    let ele = document.querySelector("body")
    if (ele) {
      ele.style.overflowX = "hidden"
      ele.style.overflowY = "hidden"
    }
    setShowModal(true)
  }

  function handleCloseModal(): any {
    let ele = document.querySelector("body")
    if (ele) {
      ele.style.overflowX = "auto"
      ele.style.overflowY = "auto"
    }
    setShowModal(false)
  }

  function isValidEmail(email?: string): any {
    if (!email) return false
    // check email format
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi
    // check if domain is common one
    let emailDomain = email.split("@")[1]
      ? email
          .split("@")[1]
          .split(".")[0]
          .toLowerCase()
      : "gmail"
    let isBasicDomain = freeBasicEmailDomain.includes(emailDomain)
    return emailRegex.test(email) && !isBasicDomain
  }

  async function sendMessageToSlack(data: SlackMessageProps): Promise<any> {
    let payload = createSlackMessagePayload(data)
    const baseURL = config.GATSBY_SLACK_POLICY_FINDER_WEBHOOK_URL
    if (!baseURL) return
    try {
      await slackAxiosInstance.post(baseURL, JSON.stringify(payload))
    } catch (e) {
      console.log(e)
    }
    try {
      let mailData = {
        name: data.userName,
        email: data.userEmail,
      }
      await sendEmailToCustomer({ variables: { input: mailData } })
    } catch (e) {
      console.log(e)
    }
    let ele = document.getElementById("csv-download-link")
    if (ele) {
      ele.click()
      handleCloseModal()
    }
    sessionStorage.setItem("modal-inputted", "true")
  }

  interface SlackMessageProps {
    userName: string
    userCompanyName: string
    userEmail: string
    states: { label: string; value: string }[]
    employeeCount: number
    industries: { label: string; value: string }[]
  }

  function sendMessageToBackend(data: any): any {
    const baseURL = config.NEW_API_HOST
    if (!baseURL) return
    try {
      fetch(`${baseURL}/capturePolicyFinderForm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
    } catch (e) {
      console.log(e)
    }
  }

  function createSlackMessagePayload(data: SlackMessageProps): any {
    let mappedStates = data.states.map((e: any) => `${e.label}`)
    let mappedIndustries = data.industries.map((e: any) => `${e.label}`)
    let payload: any = {
      blocks: [
        {
          type: "header",
          text: {
            type: "plain_text",
            text: "New message from AirMason - Policy Finder",
            emoji: true,
          },
        },
        {
          type: "rich_text",
          elements: [
            {
              type: "rich_text_section",
              elements: [
                {
                  type: "text",
                  text:
                    "User with the following information has just used the Policy Finder feature: \n",
                },
              ],
            },
            {
              type: "rich_text_list",
              style: "bullet",
              elements: [
                {
                  type: "rich_text_section",
                  elements: [
                    {
                      type: "text",
                      text: "Name: ",
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: "text",
                      text: data.userName,
                    },
                  ],
                },
                {
                  type: "rich_text_section",
                  elements: [
                    {
                      type: "text",
                      text: "Company Name: ",
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: "text",
                      text: data.userCompanyName,
                    },
                  ],
                },
                {
                  type: "rich_text_section",
                  elements: [
                    {
                      type: "text",
                      text: "Email: ",
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: "link",
                      url: `${`mailto:${data.userEmail}`}`,
                      text: data.userEmail,
                      style: {
                        bold: true,
                      },
                    },
                  ],
                },
                {
                  type: "rich_text_section",
                  elements: [
                    {
                      type: "text",
                      text: "State(s): ",
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: "text",
                      text: mappedStates.join(", "),
                    },
                  ],
                },
                {
                  type: "rich_text_section",
                  elements: [
                    {
                      type: "text",
                      text: "Employee count: ",
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: "text",
                      text: `${data.employeeCount}`,
                    },
                  ],
                },
                {
                  type: "rich_text_section",
                  elements: [
                    {
                      type: "text",
                      text: "Industries: ",
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: "text",
                      text: mappedIndustries.join(", "),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    }
    return payload
  }

  function handleLogicFocus(currentTargetId: string, eventType: string): any {
    let mappedDivIds: any = {
      "form-name": "input-name-div",
      "form-company": "input-company-div",
      "form-email": "input-email-div",
    }

    let ele = document.getElementById(mappedDivIds[currentTargetId])
    if (eventType === "focus") {
      if (ele) {
        ele.classList.remove("border-gray-10")
        ele.classList.add("border-blue")
      }
    } else if (eventType === "blur") {
      if (ele) {
        ele.classList.remove("border-blue")
        ele.classList.add("border-gray-10")
      }
    }
    return
  }

  const DownloadCSVModal = ({ show }: any) => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [inputError, setInputError] = useState(false)
    return (
      <div className={show ? "modal-here block" : "modal-here hidden"}>
        <div
          onClick={() => handleCloseModal()}
          className={`modal-backdrop-div fixed`}
          style={{
            backgroundColor: "#0000004d",
            zIndex: 51,
            backdropFilter: "blur(7px)",
            inset: "0px",
          }}
        />
        <div className={`modal-center modal-style`} style={{ zIndex: 52 }}>
          <div className="modal-body-div">
            <div
              className="close-icon-div absolute text-xl cursor-pointer"
              onClick={() => handleCloseModal()}
              style={{ top: "40px", right: "40px" }}
            >
              <img className="" src={closeIcon} alt="close-icon" />
            </div>
            <div className="explain-div">
              <p className="font-lato font-bold text-32px leading-9 text-green-4 mb-27px">
                Enter your details to download
              </p>
              <p className="font-lato font-normal text-base leading-6 mb-30px">
                Our complete checklist of reccommended and required policies for
                your handbook will download immediately.
              </p>
            </div>
            <div className="input-div-modal">
              <div
                id={"input-name-div"}
                className="mb-20px flex flex-col w-466px rounded border-gray-10 border pt-10px pb-14px px-26px"
                style={{ transition: "0.5s" }}
              >
                <label className="block font-lato text-xs leading-14px text-gray-8">
                  Your name
                </label>
                <input
                  id="form-name"
                  className="block"
                  type="text"
                  value={name}
                  onChange={e => {
                    setInputError(false)
                    setName(e.target.value)
                  }}
                  autoComplete="off"
                  style={{
                    height: "auto !important",
                    fontSize: "16px !important",
                    lineHeight: "16.65px !important",
                    color: "#A4B5B3 !important",
                    padding: "2px !important",
                  }}
                  onFocus={e => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                  onBlur={e => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                />
              </div>
              <div
                id={`input-company-div`}
                className=" mb-20px flex flex-col w-466px rounded border-gray-10 border pt-10px pb-14px px-26px"
                style={{ transition: "0.5s" }}
              >
                <label className="block font-lato text-xs leading-14px text-gray-8">
                  Your company name
                </label>
                <input
                  id="form-company"
                  className="block"
                  type="text"
                  value={companyName}
                  onChange={e => {
                    setInputError(false)
                    setCompanyName(e.target.value)
                  }}
                  autoComplete="off"
                  style={{
                    height: "auto !important",
                    fontSize: "16px !important",
                    lineHeight: "16.65px !important",
                    color: "#A4B5B3 !important",
                    padding: "2px !important",
                  }}
                  onFocus={e => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                  onBlur={e => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                />
              </div>
              <div
                id={`input-email-div`}
                className="flex flex-col w-466px rounded border-gray-10 border pt-10px pb-14px px-26px"
                style={{ transition: "0.5s" }}
              >
                <label className="block font-lato text-xs leading-14px text-gray-8">
                  Your company email
                </label>
                <input
                  id="form-email"
                  className="block"
                  type="text"
                  value={email}
                  onChange={e => {
                    setInputError(false)
                    setEmail(e.target.value)
                  }}
                  autoComplete="off"
                  style={{
                    height: "auto !important",
                    fontSize: "16px !important",
                    lineHeight: "16.65px !important",
                    color: "#A4B5B3 !important",
                    padding: "2px !important",
                  }}
                  onFocus={e => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                  onBlur={e => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="modal-footer-div mt-40px flex flex-row items-center justify-start"
            style={{ columnGap: "31px" }}
          >
            <div className="btn-div">
              <button
                className={`finder-download-btn rounded-25px bg-blue
                `}
                style={{ width: "164px", height: "50px" }}
                onClick={() => {
                  if (!isValidEmail(email) || !name || !companyName) {
                    setInputError(true)
                  } else {
                    sendMessageToBackend({
                      email,
                      name,
                      company: companyName,
                    })
                    sendMessageToSlack({
                      userName: name,
                      userEmail: email,
                      userCompanyName: companyName,
                      states: selectedStates,
                      industries: selectedIndustries,
                      employeeCount: inputEmployeeCount,
                    })
                  }
                }}
              >
                <p className="font-lato text-base leading-17px text-white">
                  Download
                </p>
              </button>
            </div>
            <div className="error-div flex flex-col">
              <p
                className={`font-lato text-base leading-17px text-red-dark ${
                  inputError ? "" : "hidden"
                }`}
              >
                {`Please enter a valid ${name ? "" : "name, "}${
                  companyName ? "" : "company name, "
                }${
                  isValidEmail(email)
                    ? ""
                    : "email address (custom/company domains only), "
                }`.slice(0, -2)}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`policies-section sm:px-8 px-0 pb-24`}
      id="policies-section"
    >
      <div className="max-w-820px mx-auto relative">
        {/* Filter Div */}
        <div
          className="filter-div max-w-820px sm:h-67px h-62px bg-white sm:rounded-73px rounded-none sm:translate-y-0 flex flex-row justify-between"
          style={{
            boxShadow: "0px 1px 3px rgba(0,0,0, 0.06)",
            padding: "0",
            // border: "1px solid #d1d1d1",
          }}
        >
          <div
            className="state-filter sm:w-250px w-full mx-auto pt-14px"
            style={{
              padding: "0 5px",
            }}
            onMouseEnter={() => setFilterDiv1Hovered(true)}
            onMouseLeave={() => setFilterDiv1Hovered(false)}
          >
            <p
              className={`filter-label state-label text-xs leading-14px sm:pl-5 pl-4 sm:pr-5 pr-4 ${
                filterDiv1Hovered ? "text-green-4" : "text-gray-8"
              }`}
            >{`State(s)`}</p>
            <div className="state-dropdown" ref={stateRef}>
              <MultiSelect
                className="multi-select-special-1 outline-none"
                options={rawStates}
                value={selectedStates}
                hasSelectAll={false}
                labelledBy="states"
                ClearIcon={<div />}
                ClearSelectedIcon={null}
                valueRenderer={(selected, _options) => {
                  return selected.length === 0 ? (
                    <p className="font-lato text-15px text-green-4 sm:pl-5 pl-4 sm:pr-20 pr-4">
                      Select
                    </p>
                  ) : selected.length === 1 ? (
                    <p className="font-lato text-15px text-green-4 sm:pl-5 pl-4 sm:pr-20 pr-4">
                      {selected[0].label}
                    </p>
                  ) : (
                    <p className="font-lato text-15px text-green-4 sm:pl-5 pl-4 sm:pr-5 pr-4">{`${
                      selected.findIndex(e => e.value === "all") === -1
                        ? selected.length
                        : selected.length - 1
                    } states`}</p>
                  )
                }}
                ItemRenderer={({ checked, option, disabled }: any) => {
                  return (
                    <div
                      className={`item-renderer flex flex-row ${
                        disabled ? "disabled" : ""
                      }`}
                      style={{ alignItems: "center !important" }}
                    >
                      <input
                        id={option.value}
                        className="border-outline-none block"
                        type="checkbox"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "17px !important",
                          borderRadius: "4px",
                        }}
                        onChange={() => {
                          if (option.isPickAll) {
                            setSelectedStates(handlePickAllStates())
                          } else {
                            setSelectedStates(handlePickOneState(option))
                          }
                          setCurrentPolicies([])
                          setClickingGo(true)
                        }}
                        checked={checked}
                        tabIndex={-1}
                        disabled={disabled}
                      />
                      <p
                        className={`font-lato text-sm text-green-4 ${
                          checked ? "font-bold" : "font-normal"
                        }`}
                      >
                        {option.label}
                      </p>
                    </div>
                  )
                }}
              />
            </div>
          </div>
          <div
            className="employee-count-filter sm:w-231px w-full sm:pl-7 pl-4 mx-auto pt-14px"
            style={{
              borderRight: "1px solid rgba(209, 209, 209, 0.5)",
              padding: "0 5px",
              borderLeft: "1px solid rgba(209, 209, 209, 0.5)",
            }}
            onMouseEnter={() => setFilterDiv2Hovered(true)}
            onMouseLeave={() => setFilterDiv2Hovered(false)}
          >
            <p
              className={`filter-label employee-web-label hidden sm:block text-xs leading-14px ${
                filterDiv2Hovered ? "text-green-4" : "text-gray-8"
              }`}
            >{`Employee Count`}</p>
            <p
              className={`filter-label employee-mobile-label sm:hidden block text-xs leading-14px ${
                filterDiv2Hovered ? "text-green-4" : "text-gray-8"
              }`}
            >{`# of Employees`}</p>
            <input
              id="employeeCount"
              className="outline-none font-lato text-15px leading-14px text-green-4 input-number-hide-arrow"
              style={{ height: "24px", padding: "0px", width: "100%" }}
              placeholder="eg.100"
              type="number"
              value={inputEmployeeCount === 0 ? 0 : inputEmployeeCount}
              onChange={e => {
                setInputEmployeeCount(parseInt(e.target.value ?? 0))
                setCurrentPolicies([])
                setClickingGo(true)
              }}
              onFocus={() => {
                let filterDiv = document.querySelector(
                  `.policies-section .filter-div`
                ) as any
                let labelWebElement = document.querySelector(
                  `.employee-web-label`
                )
                let labelMobileElement = document.querySelector(
                  `.employee-mobile-label`
                )
                if (filterDiv && labelWebElement && labelMobileElement) {
                  filterDiv.classList.add("active-outline")
                  labelWebElement.classList.add("active")
                  labelMobileElement.classList.add("active")
                }
              }}
              onBlur={() => {
                let filterDiv = document.querySelector(
                  `.policies-section .filter-div`
                ) as any
                let labelWebElement = document.querySelector(
                  `.employee-web-label`
                )
                let labelMobileElement = document.querySelector(
                  `.employee-mobile-label`
                )
                if (filterDiv && labelWebElement && labelMobileElement) {
                  filterDiv.classList.remove("active-outline")
                  labelWebElement.classList.remove("active")
                  labelMobileElement.classList.remove("active")
                }
              }}
            />
          </div>
          <div
            className="industry-filter sm:w-250px w-full mx-auto pt-14px pr-4"
            style={{ padding: "0 5px" }}
            onMouseEnter={() => setFilterDiv3Hovered(true)}
            onMouseLeave={() => setFilterDiv3Hovered(false)}
          >
            <p
              className={`filter-label industry-label text-xs leading-14px sm:pl-5 pl-4 sm:pr-5 pr-4 ${
                filterDiv3Hovered ? "text-green-4" : "text-gray-8"
              }`}
            >{`Industry`}</p>
            <div className="industry-dropdown" ref={industryRef}>
              <MultiSelect
                className="multi-select-special-2 outline-none"
                options={IndustryOptions}
                value={selectedIndustries}
                hasSelectAll={false}
                labelledBy="industry"
                ClearIcon={<div />}
                ClearSelectedIcon={null}
                valueRenderer={(selected, _options) => {
                  return selected.length === 0 ? (
                    <p className="font-lato text-15px text-green-4 sm:pl-5 pl-4 sm:pr-20 pr-4">
                      Select
                    </p>
                  ) : selected.length === 1 ? (
                    <p className="font-lato text-15px text-green-4 sm:pl-5 pl-4 sm:pr-20 pr-4">
                      {selected[0].label}
                    </p>
                  ) : (
                    <p className="font-lato text-15px text-green-4 sm:pl-5 pl-4 sm:pr-5 pr-4">{`${
                      selected.findIndex(e => e.value === "all") === -1
                        ? selected.length
                        : selected.length - 1
                    } industries`}</p>
                  )
                }}
                ItemRenderer={({ checked, option, disabled }: any) => {
                  return (
                    <div
                      className={`item-renderer flex flex-row ${
                        disabled ? "disabled" : ""
                      }`}
                      style={{ alignItems: "center !important" }}
                    >
                      <input
                        id={option.value}
                        className="border-outline-none block"
                        type="checkbox"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "17px !important",
                          borderRadius: "4px",
                        }}
                        onChange={() => {
                          if (option.isPickAll) {
                            setSelectedIndustries(handlePickAllIndustries())
                          } else {
                            setSelectedIndustries(handlePickOneIndustry(option))
                          }
                          setCurrentPolicies([])
                          setClickingGo(true)
                        }}
                        checked={checked}
                        tabIndex={-1}
                        disabled={disabled}
                      />
                      <p
                        className={`font-lato text-sm text-green-4 ${
                          checked ? "font-bold" : "font-normal"
                        }`}
                      >
                        {option.label}
                      </p>
                    </div>
                  )
                }}
              />
            </div>
          </div>
          <div
            className="go-btn-div flex flex-col justify-center relative"
            style={{ left: "-3px" }}
          >
            <button
              onClick={handleClickGo}
              disabled={!canPressGoButton()}
              className={`go-btn hidden sm:block rounded-full text-base text-white ${
                !canPressGoButton() ? `bg-blue-light-1` : `bg-blue`
              }`}
              style={{ width: "51px", height: "51px" }}
            >
              Go
            </button>
          </div>
        </div>
        <button
          onClick={handleClickGo}
          disabled={!canPressGoButton()}
          className={`block sm:hidden w-full go-btn-div text-white ${
            !canPressGoButton() ? `bg-blue-light-1` : `bg-blue`
          }`}
          style={{ height: "62px" }}
        >
          <p
            className={`max-w-262px mx-auto ${
              canPressGoButton() ? "text-lg" : "text-sm"
            }`}
          >
            {canPressGoButton()
              ? "Go"
              : "Enter your details above to see the policies that apply to your company."}
          </p>
        </button>
        {/* Policies Table Web */}
        <div className="policies-table-web max-w-820px sm:my-6 my-0 border-gray-9 border rounded bg-gray-9">
          {/* Tab Section */}
          <PolicyTabs
            currentTab={currentTab}
            selectedStates={selectedStates}
            clickingGo={clickingGo}
            setCurrentTab={setCurrentTab}
            totalRequiredPolicies={
              currentPolicies.length > 0
                ? totalRequiredPolicies
                : federalPolicies.length
            }
            totalRecommendedPolicies={totalRecommendedPolicies}
            policiesToShowOnUIRequired={policiesToShowOnUIRequired}
            policiesToShowOnUIRecommended={policiesToShowOnUIRecommended}
            federalPoliciesMerged={mergeObjects(federalPolicies)}
          />
          <DownloadCSVModal show={showModal} setShow={setShowModal} />

          {/* Table Section */}
          <div
            className={`hidden sm:block table-section px-22px bg-white py-4 ${
              currentTab === PolicyTab.REQUIRED
                ? "rounded-tr-md"
                : "rounded-tl-md"
            }`}
            style={{
              borderBottomRightRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
          >
            {/* Summary Div */}

            {currentTab === PolicyTab.RECOMMENDED && (
              <>
                <div className="summary-div flex flex-row justify-between">
                  {policiesToShowOnUIRecommended.length > 0 ? (
                    <div className="total-policies">
                      <p>
                        <span className="text-base text-green-4">
                          All recommended policies
                        </span>
                        <span className="text-base text-green-4">{` (${totalRecommendedPolicies})`}</span>
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  {((selectedStates.filter(
                    (state: any) => state.value === "federals"
                  ).length > 0 &&
                    clickingGo === false) ||
                    currentPolicies.length > 0) && <DownloadCSV />}
                </div>
                <PoliciesTableDesktop
                  policiesToShowOnUI={policiesToShowOnUIRecommended}
                />
                {policiesToShowOnUIRecommended.length === 0 && (
                  <div
                    className="font-lato text-sm text-center m-auto pt-44px pb-64px"
                    style={{ width: "298px" }}
                  >
                    <p className="" style={{ color: "#0E3A36", opacity: 0.37 }}>
                      Enter your details above to see the policies that apply to
                      your company.
                    </p>
                  </div>
                )}
              </>
            )}

            {currentTab === PolicyTab.REQUIRED && (
              <>
                <div>
                  <div className="summary-div flex flex-row justify-between">
                    {selectedStates.filter(
                      (state: any) => state.value === "federals"
                    ).length > 0 && clickingGo === false ? (
                      <div className="total-policies">
                        <p>
                          <span className="text-base text-green-4">
                            Federal Policies
                          </span>
                          <span className="text-base text-green-4">{` (${federalPolicies.length})`}</span>
                        </p>
                      </div>
                    ) : (
                      <div />
                    )}
                    {((selectedStates.filter(
                      (state: any) => state.value === "federals"
                    ).length > 0 &&
                      clickingGo === false) ||
                      currentPolicies.length > 0) && <DownloadCSV />}
                  </div>
                  {selectedStates.filter(
                    (state: any) => state.value === "federals"
                  ).length > 0 &&
                    clickingGo === false && (
                      <PoliciesTableDesktop
                        policiesToShowOnUI={mergeObjects(federalPolicies)}
                      />
                    )}
                </div>
                <div>
                  <div className="summary-div flex flex-row justify-between">
                    {policiesToShowOnUIRequired.length > 0 ? (
                      <div className="total-policies pt-6">
                        <p>
                          <span className="text-base text-green-4">
                            State specific policies
                          </span>
                          <span className="text-base text-green-4">{` (${totalRequiredStateSpecificPolicies})`}</span>
                        </p>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                  <PoliciesTableDesktop
                    policiesToShowOnUI={policiesToShowOnUIRequired}
                  />
                </div>
                {policiesToShowOnUIRequired.length === 0 &&
                  clickingGo === true && (
                    <div
                      className="font-lato text-sm text-center m-auto pt-44px pb-64px"
                      style={{ width: "298px" }}
                    >
                      <p
                        className=""
                        style={{ color: "#0E3A36", opacity: 0.37 }}
                      >
                        Enter your details above to see the policies that apply
                        to your company.
                      </p>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PoliciesSection
