import React from "react"
// @ts-ignore: Unreachable code error
import tw, { styled } from "tailwind.macro"
import { navigate } from "gatsby"
import airmasonLogoDark from "src/images/logos/airmason_logo_dark.svg"

interface HeaderProps {
  className?: string
  bgColor?: string
}

const Header: React.FC<HeaderProps> = ({ bgColor = "" }) => {
  function handleClickHeaderLogo(): any {
    if (typeof window !== "undefined" && window.location.pathname !== "/") {
      navigate("/")
    } else {
      let element = document.getElementById("landing-page")
      element ? element.scrollIntoView({ behavior: "smooth" }) : ""
    }
  }

  return (
    <div
      className={`lg:h-110px h-105px fixed w-screen z-50 lg:pt-8 pt-10 pin-t ${
        bgColor ? bgColor : "bg-white"
      }`}
    >
      <div className="max-w-820px mx-auto flex flex-col pl-27px sm:pl-0">
        <div className={`${`relative`} `}>
          <img
            onClick={() => handleClickHeaderLogo()}
            className="lg:w-167px lg:h-50px w-128px h-39px cursor-pointer"
            src={airmasonLogoDark}
            alt="logo"
          />
        </div>
      </div>
    </div>
  )
}

export default Header
