/*  eslint-disable */

import { gql, useMutation } from "src/apolloClient"

const useSendMessagePoliciesFinder = () => {
  const [submit] = useMutation(gql`
    mutation submit($input: PoliciesFinderCustomerMailInput) {
      submit(input: $input) @rest(path: "/policies-finder/send", method: "POST")
    }
  `)

  return { submit }
}

export default useSendMessagePoliciesFinder
