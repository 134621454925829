import React from "react"
import { RouteComponentProps } from "@reach/router"
import Header from "./Header"
import About from "./About"
import PoliciesSection from "./PoliciesSection"
import BookConsultationBanner from "./BookConsultation"

const PoliciesFinder: React.FC<RouteComponentProps<any>> = ({}) => {
  return (
    <div className="w-screen flex flex-col bg-white mx-auto font-sans">
      <Header bgColor="bg-gray-2" />
      <About />
      <PoliciesSection />
      <BookConsultationBanner />
    </div>
  )
}

export default PoliciesFinder
