import React from "react"

interface AboutProps {}

const About: React.FC<AboutProps> = ({}) => {
  return (
    <div className={`sm:pt-155px pt-110px px-27px bg-gray-2`} id="about">
      <div className="max-w-820px mx-auto">
        <div className="flex-1 flex flex-col text-green-4">
          <h1 className="sm:max-w-586px max-w-326px font-bold sm:text-52px text-30px sm:leading-62px leading-36px">
            Policies for your Employee Handbooks
          </h1>
          <p className="max-w-255px sm:max-w-full text-green-4 text-14px sm:text-base sm:leading-6 leading-21px pt-17px sm:pb-82px pb-6">
            Enter your company details below to see{" "}
            <span className="font-bold text-green-4 text-14px sm:text-base sm:leading-6 leading-21px">
              Required & Recommended policies
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default About
