/*  eslint-disable */
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import PoliciesFinder from "../../componentsV2/PoliciesFinder"

const PageContainer = styled.div`
  height: auto;
`

const PoliciesFinderPage = () => (
  <PageContainer>
    <Helmet>
      <meta name="robots" content="all" />
      <title>AirMason | Policies Finder</title>
      <meta
        name="description"
        content="AirMason is an online tool that makes it easy for companies to get new hires up to speed quickly with an amazing employee handbook."
      />
    </Helmet>
    <PoliciesFinder />
  </PageContainer>
)

export default PoliciesFinderPage
